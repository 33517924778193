@import "./../../../GlobalStyles/variables.scss";
.proDwm {
  &-content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: calc(100vh - 150px);
    overflow: auto;
    padding-top: 5px;
    margin-top: -0.5rem;
    &-title {
      font-size: 0.9rem;
      line-height: 0.9rem;
    }
  }
  &-footer {
    display: flex;
    gap: 1rem;
    margin: 1rem auto -0.5rem;
  }
}
.proDwm-content-warning {
  text-align: center;
  color: $form_error_color;
  font-size: 0.8rem;
  &.info {
    color: $form_value_color;
  }
}
